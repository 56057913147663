export const routesFlat = {
  landing: "/admin",
  // Articles
  newsEdit: "/news/edit",
  newsView: "/news/view",
  // Special Events
  eventsEdit: "/events/edit",
  eventsView: "/events/view",
  // General
  notFound: "*",
  makeAdmin: '/new-admin'
};

export const tagsList = [
  { display: "Blog", be: "blog" },
  { display: "Event", be: "event" },
  { display: "Press Release", be: "press_release" },
  { display: "In The News", be: "news" },
  { display: "Webinar", be: "webinar" },
  { display: "Special Event", be: "special_event" },
  { display: "Land Carbon", be: "land_carbon" },
  { display: "Activity Data", be: "activity" },
  { display: "JMRV", be: "jmrv" },
  { display: "LUCA", be: "luca" },
  { display: "Tree Level", be: "tree_level" },
];

export const landingOptions = [
  {
    display: "User Management",
    options: [
      {
        id: "make-admin",
        display: "Add New Admin",
        link: routesFlat.makeAdmin,
      },
    ],
  },
  {
    display: "Articles",
    options: [
      {
        id: "display-news",
        display: "View Articles",
        link: routesFlat.newsView,
      },
      {
        id: "edit-news",
        display: "Add Article",
        link: routesFlat.newsEdit,
      },
    ],
  },
  {
    display: "Special Events",
    options: [
      {
        id: "display-events",
        display: "View Special Events",
        link: routesFlat.eventsView,
      },
      {
        id: "edit-events",
        display: "Add Special Event",
        link: routesFlat.eventsEdit,
      },
    ],
  },
];

export const articleStates = [
  {id: "PUBLISHED", display: 'Published'},
  {id: "PARTIAL", display: 'Product Only'},
  {id: "DRAFT", display: 'Draft'},
]