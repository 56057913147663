import { apiPrefix, getToken } from "./constants";

export function makeAdmin(email) {
  return fetch(`${apiPrefix}/user/set_admin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ email }),
  }).then((resp) => resp.json());
}
