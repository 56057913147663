import clsx from "clsx";

export const parentContainer = clsx(
  "h-screen relative w-full justify-center flex items-center"
);
export const header = clsx("text-darkGray font-bold text-4xl");
export const box = clsx("flex flex-col p-6 w-[400px]");
export const dropdown = clsx("bg-lime py-2 px-4 rounded-md text-sm");
export const boxesContainer = clsx(
  "flex bg-gray rounded-md justify-around mt-10 -mb-20"
);
export const reqContainer = clsx("bg-lightRed rounded-md text-center p-2 mb-2");
export const reccContainer = clsx("bg-purple rounded-md text-center p-2 mb-2");
export const subheader = clsx("font-bold mt-6 mb-[2px]");
export const input = clsx("rounded-md py-2 px-4");
