import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import * as helpers from "../../../helpers";
import * as components from './components'

const htmlLabel = "html";
const richTextLabel = "rich text";
const radioOptions = [richTextLabel, htmlLabel];

export default function NewsEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [missingField, setMissingField] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [article, setArticleObj] = useState(undefined);
  const [title, onTitleChange] = useState(undefined);
  const [summary, onSummaryChange] = useState(undefined);
  const [date, onDateChange] = useState(undefined);
  const [imgURL, onImgChange] = useState(undefined);
  const [imgALT, onAltChange] = useState(undefined);
  const [tags, onTagsChange] = useState([]);

  const defaultUri = "your-uri-goes-here";
  const [uri, onUriChange] = useState(defaultUri);

  const [richText, onRichTextChange] = useState(
    RichTextEditor.createEmptyValue()
  );

  const text = richText.toString("html");
  const [textDisplay, onTextBodyChange] = useState(richTextLabel);
  const [htmlText, onHTMLChange] = useState(undefined);

  useEffect(() => {
    if (id) {
      helpers.getArticle(id).then((obj) => {
        const date = helpers.formatDatePicker(obj.publish_date);
        const body = RichTextEditor.createValueFromString(obj.body, "html");
        const tags = obj.product_tags.concat(obj.article_tags);
        const newObj = { ...obj, tags };
        
        setArticleObj(newObj);
        onTitleChange(obj.title);
        onUriChange(obj.uri);
        onDateChange(date);
        onImgChange(obj.image_url);
        onAltChange(obj.image_credits);
        onRichTextChange(body);
        onSummaryChange(obj.summary);
        onTagsChange(tags);
      });
    }
  }, [id]);

  useEffect(() => {
    onHTMLChange(richText.toString("html"));
  }, [richText]);

  useEffect(() => {
    if (confirmMsg) {
      setTimeout(() => setConfirmMsg(false), 2400);
    }
    if (errorMsg) {
      setTimeout(() => setErrorMsg(""), 2400);
    }
    if (missingField) {
      setTimeout(() => setMissingField(false), 2400);
    }
  }, [missingField, confirmMsg, errorMsg]);

  const onTitle = (e) => {
    onTitleChange(e.target.value);
  };
  const onUri = (e) => {
    const newUri = e.target.value.substring(1);
    onUriChange(newUri);
  };
  const onDate = (e) => {
    onDateChange(e.target.value);
  };
  const onImgURL = (e) => {
    onImgChange(e.target.value);
  };
  const onAlt = (e) => {
    onAltChange(e.target.value);
  };
  const onSummary = (e) => {
    onSummaryChange(e.target.value);
  };
  const onTags = (selectedItem) => {
    const duplicate = tags.includes(selectedItem.be);
    let tagsArr;

    if (duplicate) {
      tagsArr = tags.filter((existingItem) => existingItem !== selectedItem.be);
    } else {
      tagsArr = [...tags, selectedItem.be];
    }

    onTagsChange(tagsArr);
  };
  const onHTML = (e) => {
    const newRichText = RichTextEditor.createValueFromString(
      e.target.value,
      "html"
    );
    onRichTextChange(newRichText);
    onHTMLChange(e.target.value);
  };

  const patchRequest = () => {
    let body;

    if (id) {
      if (article?.title !== title) {
        body = { ...body, title };
      }
      if (date) {
        const convertedDate = new Date(date).toISOString();
        if (article?.publish_date !== convertedDate) {
          body = { ...body, publish_date: convertedDate };
        }
      }
      if (article?.uri !== uri) {
        body = { ...body, uri };
      }
      if (article?.image_url !== imgURL) {
        body = { ...body, image_url: imgURL };
      }
      if (article?.image_credits !== imgALT) {
        body = { ...body, image_credits: imgALT };
      }
      if (article?.body !== text) {
        body = { ...body, body: text };
      }
      if (article?.summary !== summary) {
        body = { ...body, summary };
      }

      const tagsDontChange =
        article?.tags.length === tags.length &&
        article?.tags.every((tag) => tags.includes(tag));

      if (!tagsDontChange) {
        body = { ...body, tags };
      }

      if (body) {
        return helpers.editArticle(id, body);
      }
    }
  };

  const postRequest = () => {
    let convertedDate = undefined;

    if (date) {
      convertedDate = new Date(date).toISOString();
    }

    const body = {
      title,
      publish_date: convertedDate,
      uri,
      image_url: imgURL,
      image_credits: imgALT,
      body: text,
      summary,
      tags
    };

    helpers.createArticle(body).then((resp) => {
      if (resp.message.includes("Success")) {
        setConfirmMsg(true);
      } else {
        setErrorMsg(resp.message);
      }
    });
  };

  const onSaveStayClick = () => {
    if (article) {
      patchRequest().then(() => setConfirmMsg(true));
    }
    const notDefaultUri = uri !== defaultUri;
    if (title && notDefaultUri && date && !article) {
      postRequest();
    } else {
      return setMissingField(true);
    }
  };

  const useSaveExitClick = () => {
    onSaveStayClick();
    navigate(-1);
  };

  const diuProps = { uri, onUri, date, onDate, imgURL, onImgURL };
  const sumALTProps = { imgURL, imgALT, onAlt, summary, onSummary };
  const tagsProps = { onTags, tags };

  return (
    <div className="w-full flex justify-center items-center mt-[50px]">
      <div className="w-3/4">
        <input
          className={"border-b-2 p-2 focus:outline-none w-full mt-10"}
          type="title"
          value={title}
          placeholder={title || "Write Article Title Here"}
          onChange={onTitle}
        />
        <div className="flex flex-row w-full mt-4">
          {/* sidebar edit options */}
          <div className="flex flex-col mr-10 w-1/3">
            <components.DateImgURI {...diuProps} />
            <components.SumALT {...sumALTProps} />
            <components.Tags {...tagsProps} />
          </div>

          {/* primary text body */}
          <div className="w-2/3">
            <div className="mb-4">
              {radioOptions.map((option) => {
                return (
                  <label className="container mr-6" key={option}>
                    <input
                      type="radio"
                      value={option}
                      onChange={() => onTextBodyChange(option)}
                      checked={textDisplay === option}
                    />
                    <span className="radiobtn"></span>
                    <span className="ml-2 uppercase">{option}</span>
                  </label>
                );
              })}
            </div>
            {textDisplay === richTextLabel ? (
              <RichTextEditor
                value={richText}
                onChange={onRichTextChange}
                className={textBodyStyle}
              />
            ) : (
              <textarea
                className={textBodyStyle + " border-2 rounded-md"}
                value={htmlText}
                onChange={onHTML}
              />
            )}
            <div className="h-[20px] mt-4">
              {missingField && (
                <div className="text-red">
                  You must add a title, date, and URI in order to save.
                </div>
              )}
              {confirmMsg && (
                <div className="text-[green]">Your article has been saved!</div>
              )}
              {errorMsg && <div className="text-red">{errorMsg}</div>}
            </div>
            <div className="w-full flex justify-between">
              <button className={submitBtn} onClick={onSaveStayClick}>
                save & keep editing
              </button>
              <button className={submitBtn} onClick={useSaveExitClick}>
                save & go back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const labelStyle =
  "first:mt-0 font-bold mt-4 bg-lightgray/50 px-2 rounded-t-md";
const inputStyle =
  "p-2 w-full h-10 border-b-2 text-sm focus:outline-none cursor-pointer";
const textBodyStyle = "max-w-[800px] w-full h-[640px] overflow-y-scroll ";
const submitBtn =
  "h-10 w-1/2 mr-4 last:mr-0 uppercase bg-black px-2 text-white font-bold rounded-md mt-5 mb-10";
