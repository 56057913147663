import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { CMSContext } from "../../..";
import { routesFlat } from "../../../constants";

export default function BackHomeButton() {
  const navigate = useNavigate();
  const { state } = useContext(CMSContext);

  const adminRoute = `${routesFlat.landing}#${state?.token ? state.token : ""}`;
  const goBackHome = () => {
    navigate(state ? adminRoute : routesFlat.login);
  };
  return (
    <div className={backHomeBtn} onClick={goBackHome}>
      go back to home
    </div>
  );
}

const backHomeBtn = clsx(
  "bg-lime rounded-lg text-xs py-2 px-4 w-[160px] mt-10 text-center cursor-pointer font-bold uppercase"
);
