import { useContext } from "react";
import { CMSContext } from "../../..";

export default function EventsDropdown() {
  const { state } = useContext(CMSContext);
  return (
    <>
      <option value="" disabled></option>
      {state.stac?.map((layer) => (
        <option key={layer.stac_collection_id} value={layer.stac_collection_id}>
          {layer.activity_types[0]}
        </option>
      ))}
    </>
  );
}
