import { getToken, cmsPrefix } from "./constants";

export function getArticle(id) {
  return fetch(`${cmsPrefix}/newsfeed/item?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function editArticle(id, body) {
  return fetch(`${cmsPrefix}/newsfeed/item?id=${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function createArticle(body) {
  return fetch(`${cmsPrefix}/newsfeed/item`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
  })
    .then((resp) => resp.json())
    .catch((err) => err.message);
}

export function updateArticleState(id, state) {
  return fetch(`${cmsPrefix}/newsfeed/item?id=${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ state }),
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function getAllArticles(args = '') {
  return fetch(`${cmsPrefix}/newsfeed/summary${args}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((resp) => resp.json())
    .then((arr) => arr);
}
