import { useEffect, useState } from "react";
import { makeAdmin } from "../../helpers";
import clsx from "clsx";

export default function MakeAdmin() {
  const defaultSuccess = { state: 0, message: "" };
  const [email, setEmail] = useState();
  const [success, setSuccess] = useState(defaultSuccess);

  const makeUserAdmin = () => {
    console.log(email);
    makeAdmin(email).then((result) => {
      if (result.error) {
        setSuccess({ state: -1, message: result.message });
        return;
      }

      setSuccess({ ...success, state: 1 });
    });
  };

  useEffect(() => {
    setTimeout(() => setSuccess(defaultSuccess), 6000);
  }, [success.state]);

  return (
    <div className={parentContainerStyle}>
      <div className="flex flex-col relative">
        <div className={headerStyle}>Add New Admin</div>
        <div>
          <input
            className={inputStyle}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Type User Email Here"
          />
        </div>
        <button className={submitBtnStyle} onClick={makeUserAdmin}>
          {success.state === 1 && "Success!"}
          {success.state === 0 && "Submit"}
          {success.state === -1 && "Error"}
        </button>
        <div className="absolute bottom-0 -mb-10">{success.message}</div>
      </div>
    </div>
  );
}

const parentContainerStyle = clsx(
  "w-full h-screen flex justify-center items-center"
);
const headerStyle = clsx("font-bold text-darkGray text-4xl");
const submitBtnStyle = clsx(
  "bg-lime rounded-md mt-4 px-4 py-2 text-center max-w-[120px]"
);
const inputStyle = clsx("border-b-2 pb-4 focus:outline-none mt-10 w-[500px]");
