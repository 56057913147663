export function formatISO(date) {
  return new Date(date).toISOString().slice(0, -5);
}

export function isISOReady(date) {
  const dateFormatted = new Date(date);

  function isValidDate(date) {
    if (!(date instanceof Date) || isNaN(date)) {
      return false;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Make sure that the inputed year is after 1900
    if (year < 1900) {
      return false;
    }

    const checkDate = new Date(year, month, day);
    return checkDate.getDate() === day;
  }

  return isValidDate(dateFormatted);
}
