import { useContext } from "react";
import { CMSContext } from "../../..";
import * as helpers from "../../../helpers";
import { ViewTemplate } from "../../templates";
import { routesFlat } from "../../../constants";

export default function NewsView() {
  const { state } = useContext(CMSContext);

  const viewProps = {
    items: state.articles,
    updateItem: helpers.updateArticleState,
    deleteItem: helpers.updateArticleState,
    addNewItem: routesFlat.newsEdit,
    headerText: "Your News Articles",
  };
  return <ViewTemplate {...viewProps} />;
}
