import { useState, useEffect } from "react";
import * as helpers from "../../../helpers";
import { ViewTemplate } from "../../templates";
import { routesFlat } from "../../../constants";

export default function ViewEdit() {
  const [items, setItems] = useState();
  
  useEffect(() => {
    helpers.getAllEvents().then((items) => setItems(items));
  }, []);

  const viewProps = {
    items,
    updateItem: helpers.updateEventFilter,
    deleteItem: helpers.deleteEvent,
    addNewItem: routesFlat.eventsEdit,
    headerText: "Your Special Events",
  };
  return <ViewTemplate {...viewProps} />;
}
