import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import * as helpers from "../../../helpers";
import { EventsDropdown } from "../../templates";
import ArticlesDropdown from "./components/ArticlesDropdown";
import * as styles from "./styles";
import { routesFlat } from "../../../constants";
import { isEqual } from "lodash";
import CTASection from "./components/CTASection";

export default function EventsEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedLayer, setSelectedLayer] = useState("");
  const [selectedArticle, setSelectedArticle] = useState("");

  const dateDefault = { startDate: "", endDate: "" };
  const [date, setDate] = useState(dateDefault);

  const [event, setEvent] = useState({});
  const [original, setOrignal] = useState({});
  const [success, setSuccess] = useState(0); // Need positive, negative, and neutral states

  useEffect(() => {
    if (id) {
      helpers.getEvent(id).then((obj) => {
        const endDate = obj.end_date
          ? helpers.formatDatePicker(obj.end_date)
          : "";
        const startDate = obj.start_date
          ? helpers.formatDatePicker(obj.start_date)
          : "";

        setDate({ endDate, startDate });
        setSelectedLayer(obj.product_filter);
        setSelectedArticle(obj.newsfeed_uri);
        setEvent(obj);
        setOrignal(obj);
      });
    }
  }, [id]);

  // ACTIVITY PRODUCT LAYER
  const onSelectedLayerDropdown = (e) => {
    // Need to send a different attr to BE than what we recieve
    setEvent({ ...event, product_stac_id: e.target.value });

    setSelectedLayer(e.target.value);
  };

  // CONNECTED ARTICLE
  const onSelectedArticleDropdown = (newsfeed_id, newsfeed_uri) => {
    setEvent({ ...event, newsfeed_id, newsfeed_uri });
    setSelectedArticle(newsfeed_uri);
  };

  //GID
  const onGIDChange = (e) => setEvent({ ...event, gid: e.target.value });

  // START AND END DATE
  const onStartDateChange = (e) => {
    setDate({ ...date, startDate: e.target.value });
  };
  const onEndDateChange = (e) => {
    const ready = helpers.isISOReady(e.target.value);

    if (!e.target.value) {
      setEvent({ ...event, end_date: null });
    }
    if (ready) {
      setEvent({ ...event, end_date: helpers.formatISO(e.target.value) });
    }
    setDate({ ...date, endDate: e.target.value });
  };

  // COORDINATES
  const onLatChange = (e) => {
    if (!e.target.value) {
      setEvent({ ...event, location_latitude: null });
    }
    setEvent({ ...event, location_latitude: e.target.value });
  };
  const onLongChange = (e) => {
    if (e.target.value) {
      setEvent({ ...event, location_longitude: null });
    }
    setEvent({ ...event, location_longitude: e.target.value });
  };

  // Set Message State
  const setSuccessTimeout = (state) => {
    const timeout = setTimeout(() => {
      setSuccess(state);
    }, 250);

    return () => clearTimeout(timeout);
  };

  // Clear Message State
  useEffect(() => {
    const timeout = setTimeout(() => setSuccess(0), 3000);

    return () => clearTimeout(timeout);
  }, [success]);

  // On Saving New or Editted Special Event
  const onSave = () => {
    const requiredInputs =
      !!event?.gid &&
      !!event?.newsfeed_uri &&
      !!date?.startDate &&
      !!(event?.product_filter || event?.product_stac_id);

    if (!requiredInputs) {
      setSuccessTimeout(-2);
      return;
    }

    const start_date = helpers.formatISO(date.startDate)
    let end_date = null;

    if (date.endDate) {
      end_date = helpers.formatISO(date?.endDate)
    }

    if (original?.id) {
      const eventWithDates = {...event, start_date, end_date}
      const noUpdates = isEqual(eventWithDates, original);


      if (noUpdates) {
        setSuccessTimeout(2);
        return;
      }

      helpers.editEvent(original.id, eventWithDates);
      setSuccessTimeout(1);
      return;
    }

    helpers
      .createEvent({ ...event, start_date, end_date })
      .then(() => setSuccessTimeout(1));
  };

  const onCancel = () => {
    navigate(-1);
  };

  const articlesProps = {
    onSelectedArticleDropdown,
    selectedArticle,
  };

  const ctaProps = {
    onCancel,
    onSave,
    success,
  };
  return (
    <div className={styles.parentContainer}>
      <div className="flex flex-col w-3/4">
        <div className={styles.header}>
          {id ? "Edit Your" : "Create New"} Special Event
        </div>

        <div className={styles.boxesContainer}>
          <div className={styles.box}>
            <div className={styles.reqContainer}>REQUIRED</div>
            {/* GID */}
            <div className={styles.subheader}>What is this events GID?</div>
            <input
              className={styles.input}
              value={event?.gid}
              onChange={onGIDChange}
            ></input>
            {/* START DATE */}
            <div className={styles.subheader}>Start Date</div>
            <input
              className={styles.input}
              type="date"
              value={date.startDate}
              onChange={onStartDateChange}
            ></input>
            {/* ACTIVITY LAYER */}
            <div className={styles.subheader}>
              Which activity layer connects to this event?
            </div>
            <select
              id={id}
              value={selectedLayer}
              onChange={onSelectedLayerDropdown}
              className={styles.dropdown}
            >
              <EventsDropdown />
            </select>
            {/* ASSOCIATED ARTICLE */}
            <ArticlesDropdown {...articlesProps} />
            <div className="text-[10px] text-darkGray mt-2">
              Can't find the article you need?
              <Link className="ml-[3px]" to={routesFlat.newsView}>
                Make sure it's set as{" "}
                <span className="underline">
                  "Published" or "Product Only" in articles
                </span>
              </Link>{" "}
              and has a <span className="italic">Special Event</span> tag.
            </div>
          </div>

          <div className={styles.box}>
            <div className={styles.reccContainer}>RECOMMENDED</div>
            {/* LATITUDE AND LONGITUDE */}
            <div className={styles.subheader}>Latitude</div>
            <input
              className={styles.input}
              value={event?.location_latitude}
              onChange={onLatChange}
            ></input>
            <div className={styles.subheader}>Longitude</div>
            <input
              className={styles.input}
              value={event?.location_longitude}
              onChange={onLongChange}
            ></input>
            {/* END DATE */}
            <div className={styles.subheader}>End Date</div>
            <input
              className={styles.input}
              type="date"
              value={date.endDate}
              onChange={onEndDateChange}
            ></input>
            <CTASection {...ctaProps} />
          </div>
        </div>
      </div>
    </div>
  );
}
