import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { routesFlat } from "../../../constants";
import * as pages from "../..";
import { CMSContext } from "../../..";

export const routes = [
  {
    display: "/",
    path: `${routesFlat.landing}`,
    element: <pages.Landing />,
  },
  {
    display: "News View",
    path: routesFlat.newsView,
    element: <pages.NewsView />,
  },
  {
    display: "New Article",
    path: routesFlat.newsEdit,
    element: <pages.NewsEdit />,
  },
  {
    display: "Edit Article",
    path: `${routesFlat.newsEdit}/:id`,
    element: <pages.NewsEdit />,
  },
  {
    display: "View Events",
    path: `${routesFlat.eventsView}`,
    element: <pages.EventsView />,
  },
  {
    display: "New Event",
    path: `${routesFlat.eventsEdit}`,
    element: <pages.EventsEdit />,
  },
  {
    display: "Edit Event",
    path: `${routesFlat.eventsEdit}/:id`,
    element: <pages.EventsEdit />,
  },
  {
    display: "Add New Admin",
    path: `${routesFlat.makeAdmin}`,
    element: <pages.MakeAdmin />,
  },
  {
    display: "404",
    path: "*",
    element: <pages.Redirect />,
    displayInNav: false,
  },
];

const RoutesMap = (routes) => {
  const { state } = useContext(CMSContext);
  return routes.map(({ path, element }) => {
    return (
      <Route
        path={path}
        key={path}
        element={state ? element : <pages.Redirect />}
      />
    );
  });
};

export default function RoutesComp() {
  return <Routes>{RoutesMap(routes)}</Routes>;
}
