import { dropdown, subheader } from "../styles";
import { articleStates } from "../../../../constants";
import { getAllArticles } from "../../../../helpers";
import { useEffect, useState } from "react";

export default function ArticlesDropdown({
  selectedArticle,
  onSelectedArticleDropdown,
}) {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    getAllArticles("?tags=special_event").then((allAvailable) =>
      setArticles(allAvailable)
    );
  }, []);

  const onSelectedDropdown = (e) => {
    const options = e.target.options;
    const dataset = options[options.selectedIndex].dataset;
    onSelectedArticleDropdown(dataset.id, dataset.uri);
  };
  return (
    <>
      <div className={subheader}>Which article should this event point to?</div>
      <select
        id={selectedArticle}
        value={selectedArticle}
        onChange={onSelectedDropdown}
        className={dropdown}
      >
        <option value="" disabled></option>
        {articles.map((article) => {
          return (
            <option
              data-id={article.id}
              data-uri={article.uri}
              key={article.id}
              value={article.uri}
            >
              {article.title}
            </option>
          );
        })}
      </select>
    </>
  );
}
