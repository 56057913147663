import * as styles from "./styles";
import PropTypes from 'prop-types';

export default function DeleteConfirmationModal({ title, onCancel, onDelete }) {
  return (
    <div className={styles.overlayContainer}>
      <div className={styles.parentContainer}>
        <div className={styles.nestedContainer}>
          <div>Are you sure you want to delete?</div>
          <div className={styles.titleText}>{title}</div>
          <div className={styles.btnContainer}>
            <div className={styles.deleteConfirmBtn} onClick={onCancel}>
              Cancel
            </div>
            <div
              className={styles.deleteConfirmBtn + " bg-red"}
              onClick={onDelete}
            >
              Confirm, Delete.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DeleteConfirmationModal.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
