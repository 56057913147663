import { useState } from "react";
import { Link } from "react-router-dom";
import { landingOptions } from "../../constants";
import * as styles from './styles'

export default function Landing() {
  return (
    <div className={styles.parentContainer}>
      <div className="w-3/4 mt-[100px]">
        <div className="w-full flex justify-between">
          <div className={styles.welcomeTitle}>welcome to your CMS panel</div>
        </div>
        <div className={styles.optionsContainer}>
          {landingOptions.map((item) => (
            <OptionExpand item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

function OptionExpand({ item }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex mb-6 items-center">
      <div onClick={() => setOpen(!isOpen)} className={styles.optionBtn}>
        {item.display}
        <div className={"text-[10px] " + (isOpen ? " rotate-180" : "")}>►</div>
      </div>
      <div>
        {isOpen &&
          item?.options.map((option) => {
            return (
              <Link to={option.link} key={option.display} className={styles.linkBtn}>
                {option.display}
              </Link>
            );
          })}
      </div>
    </div>
  );
}
