import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import * as components from "../components";
import { RowItem } from "./components";

export default function ViewTemplate({
  items,
  headerText,
  updateItem,
  deleteItem,
  addNewItem,
}) {
  const [confirmDelete, setConfirmDelete] = useState({ display: false });
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    setDisplayItems(items);
  }, [items]);

  // Confirmation Modal Props
  const onCancel = () => setConfirmDelete(false);
  const onDelete = () => {
    onCancel();
    // Delete on backend database
    deleteItem(confirmDelete.id, "DELETED");
    // Delete on frontend view
    const index = displayItems.findIndex(
      (item) => item.id === confirmDelete.id
    );
    displayItems.splice(index, 1);
    setDisplayItems(displayItems);
  };
  const modalProps = {
    title: confirmDelete.title,
    onCancel,
    onDelete,
  };

  return (
    <div className={parentContainer}>
      <div className="w-3/4 mb-20 mt-[60px]">
        <components.BackHomeButton />
        <div className={header}>{headerText}</div>
        <div className="flex flex-col items-end">
          <div className={itemsContainer}>
            {displayItems?.length > 0 ? (
              displayItems.map((item) => {
                const rowProps = { ...item, setConfirmDelete, updateItem };
                return <RowItem {...rowProps} />;
              })
            ) : (
              <div className="w-full">
                {Array(6)
                  .fill(null)
                  .map((i) => (
                    <Skeleton key={i} className="h-[60px] w-full" />
                  ))}
              </div>
            )}
          </div>
          <Link to={addNewItem} className="mt-4 mr-4 rounded-full hover:bg-lime">
            <AddCircleRoundedIcon sx={{ fontSize: "40px" }} />
          </Link>
        </div>
      </div>
      {confirmDelete?.display && (
        <components.DeleteConfirmationModal {...modalProps} />
      )}
    </div>
  );
}

const parentContainer = clsx("w-full min-navscreen flex justify-center");
const header = clsx("font-bold mb-10 text-darkGray text-4xl mt-4");
const itemsContainer = clsx("w-full flex flex-col flex-wrap items-center");
