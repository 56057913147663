export default function Redirect() {
  const navigateToCMS = () => {
    window.location.href = process.env.REACT_APP_REDIRECT_LOGIN;
  };
  return (
    <div className={container}>
      <div className="flex flex-col">
        <div className={errorText}>
          Oops... you shouldn't be here!
        </div>
        <button className={btn} onClick={navigateToCMS}>
          Click here to go to the CMS
        </button>
      </div>
    </div>
  );
}
const container = "w-full h-screen flex justify-center items-center";
const errorText = "text-center text-4xl font-bold mb-10 -mt-20";
const btn = "h-10 w-full bg-black text-white font-bold rounded-md";
