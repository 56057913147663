import { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { formatDatePicker } from "../../../helpers";
import { routesFlat, articleStates } from "../../../constants";
import { EventsDropdown } from ".";

export default function RowItem(item) {
  // GENERIC
  const { id, setConfirmDelete, updateItem } = item;
  // ARTICLE
  const { publish_date, title, state } = item;
  // SPECIAL EVENT
  const { gid, newsfeed_uri, product_filter } = item;
  
  const [selected, setSelected] = useState(state || product_filter);
  const toEdit =
    (title ? routesFlat.newsEdit : routesFlat.eventsEdit) + "/" + id;
  const currentDate = formatDatePicker(publish_date);

  const onSelectedDropdown = (e) => {
    setSelected(e.target.value);
    updateItem(id, e.target.value);
  };

  const onDeleteConfirm = () => {
    setConfirmDelete({ display: true, id, title });
  };

  return (
    <div className={rowStyle}>
      <div className="flex items-center text-sm">
        <div className="font-bold truncate w-[500px] ml-8">
          {title || newsfeed_uri}
        </div>
        <div className="text-xs ml-4 italic">
          {publish_date ? currentDate : gid}
        </div>
      </div>
      <div className={actionContainerStyle}>
        <select
          id="draftOrPublishOrPartial"
          value={selected}
          onChange={onSelectedDropdown}
          className="focus:outline-none text-xs cursor-pointer"
        >
          {title ? <ArticleDropdownOptions /> : <EventsDropdown />}
        </select>
        <div className="self-end flex">
          <Link to={toEdit} className={editStyle}>
            <EditIcon />
          </Link>
          <div onClick={onDeleteConfirm} className={deleteStyle}>
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

function ArticleDropdownOptions() {
  return articleStates.map((state) => (
    <option value={state.id}>{state.display}</option>
  ));
}

const actionContainerStyle = clsx("w-[300px] flex items-center justify-around");
const editStyle = clsx(
  "text-xs font-bold mr-5 p-2 rounded-full hover:bg-lightgray/50"
);
const deleteStyle = clsx(
  "text-xs font-bold p-2 rounded-full hover:bg-lightgray/50"
);
const rowStyle = clsx(
  "w-full py-4 px-2 mr-10 items-center flex justify-between shadow-md" +
    " first:rounded-t-md last:rounded-b-md border-x-2 border-t-2 last:border-b-2"
);
