import { useState, createContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import { default as Routes } from "./pages/components/Routes";
import { getStacCollection, getAllArticles } from "./helpers";
import "./input.css";

export const CMSContext = createContext(null);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>
);

export function Root() {
  const location = useLocation();

  const [token, setToken] = useState({});
  const [stac, setStac] = useState({});
  const [articles, setArticles] = useState({});
  const [state, setState] = useState({});

  useEffect(() => {
    setState({ token, stac, articles });
  }, [token, stac, articles]);

  useEffect(() => {
    const path = location.pathname;
    const updatedToken = location.hash.replace("#", "");

    const isJWT = (token) => {
      const parts = token.split(".");
      if (parts.length !== 3) {
        return false;
      }
      const base64urlPattern = /^[A-Za-z0-9-_]+$/;
      return parts.every((part) => base64urlPattern.test(part));
    };

    if (updatedToken && isJWT(updatedToken)) {
      localStorage.setItem("token", updatedToken);
      setToken(updatedToken);
    }
  }, [location.hash]);

  useEffect(() => {
    getStacCollection().then((stac) => setStac(stac));
    getAllArticles().then((articles) => setArticles(articles));
  }, []);

  return (
    <CMSContext.Provider value={{ state }}>
      <Routes />
    </CMSContext.Provider>
  );
}
