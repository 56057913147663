import clsx from "clsx";

export const parentContainer = clsx(
  "w-full h-screen flex flex-col items-center"
);
export const welcomeTitle = clsx(
  "font-bold text-darkGray text-6xl text-center capitalize"
);
export const optionsContainer = clsx("mt-10 bg-gray rounded-md p-6");
export const optionBtn = clsx(
  "w-[240px] text-center font-bold text-darkGray h-[50px] px-6 py-2 shadow-md bg-lime hover:bg-darkGray hover:text-white flex items-center justify-between text-lg cursor-pointer rounded-md"
);
export const linkBtn = clsx(
  "first:ml-10 hover:bg-blue mx-4 px-4 py-2 shadow-md bg-white border-[1px] rounded-full"
);
