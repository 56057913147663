import clsx from "clsx";

export const overlayContainer = clsx("absolute z-10 h-full w-full bg-black/50");
export const titleText = clsx("font-bold text-sm line-clamp-3 mt-4");

export const parentContainer = clsx(
  "h-full w-full flex justify-center items-center"
);
export const nestedContainer = clsx(
  "h-1/3 p-4 rounded-md w-1/4 bg-white -mt-10 relative"
);

export const deleteConfirmBtn = clsx(
  "text-xs bg-gray/50 px-4 py-2 rounded-lg cursor-pointer w-[150px] text-center mb-2 "
);
export const btnContainer = clsx(
  "flex flex-col justify-center items-center -ml-4 absolute bottom-0 py-4 w-full"
);
