import { getToken, cmsPrefix } from "./constants";

export function getEvent(id) {
  return fetch(`${cmsPrefix}/newsfeed/special_event?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function createEvent(body) {
  return fetch(`${cmsPrefix}/newsfeed/special_event`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
  })
    .then((resp) => resp.json())
    .catch((err) => err.message);
}

export function deleteEvent(id) {
  return fetch(`${cmsPrefix}/newsfeed/special_event?id=${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function editEvent(id, body) {
  return fetch(`${cmsPrefix}/newsfeed/special_event?id=${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function updateEventFilter(id, product_stac_id) {
  return fetch(`${cmsPrefix}/newsfeed/special_event?id=${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ product_stac_id }),
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function getAllEvents() {
  return fetch(`${cmsPrefix}/newsfeed/special_events`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((resp) => resp.json())
    .then((arr) => arr);
}
