import clsx from "clsx";

export default function CTASection({ onSave, onCancel, success }) {
  return (
    <div className="flex justify-around items-center mt-10">
      <div className={btnStyle} onClick={onSave}>
        {success === 2 && "No New Changes"}
        {success === 1 && "Success !!"}
        {success === 0 && "Save Event"}
        {success === -1 && "Error"}
        {success === -2 && "Required Missing"}
      </div>
      <div className={btnStyle} onClick={onCancel}>
        Cancel & Go Back
      </div>
    </div>
  );
}

export const btnStyle = clsx(
  "py-2 px-6 border-2 rounded-md cursor-pointer text-sm w-1/2 text-center first:mr-2"
);
